import {
    Button,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure,
    useToast,
} from "@chakra-ui/react";
import FormRemoteSelectInput, {
    SelectOptionType,
} from "components/FormRemoteSelectInput";
import { queryClient } from "index";
import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { FaPen } from "react-icons/fa";
import { findAllCompanys, linkCompanyCustumer } from "services/api.service";

const RegisterCompany: FC<{ customerId: string, id: string }> = ({ customerId,id }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);
  const { control, handleSubmit } = useForm<{
    company: SelectOptionType;
  }>();

  const save = async (dataForm: {
    company: SelectOptionType;
  }) => {
    setLoading(true);
    linkCompanyCustumer(customerId, dataForm?.company?.value)
      .then(() => {
        queryClient.refetchQueries({
          queryKey: ["customer"],
          type: "active",
        });
        onClose();
        toast({
          title: "Sucesso",
          description: "Cliente principal adicionada com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: "Erro",
          description:
            err?.response?.data?.message || "Erro ao alterar vínculo",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Button
        onClick={onOpen}
        size="sm"
        rounded="sm"
        variant="outline"
        colorScheme="brand"
      >
        <FaPen />
        <Text ml={2}>Vincular Empresa</Text>
      </Button>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Vincular Empresa</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            <FormRemoteSelectInput
              control={control}
              name="company"
              registerOptions={{
                required: "Campo obrigatório",
              }}
              label="Empresa"
              loadDataFn={({ value, cb }) =>
                findAllCompanys({ filter: value, limit: 20, page: 0 }).then(
                  (retorno) => {
                    if (retorno?.registers?.length > 0) {
                      cb(
                        retorno.registers?.map((d: any) => ({
                          label: d.name,
                          value: d.id,
                        }))
                      );
                    } else {
                      cb([]);
                    }
                  }
                )
              }
            />
            </ModalBody>
            <ModalFooter>
              <HStack spacing={4}>
                <Button
                  colorScheme="red"
                  isLoading={loading}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  colorScheme="green"
                  isLoading={loading}
                  onClick={handleSubmit(save)}
                >
                  Adicionar
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default RegisterCompany;
