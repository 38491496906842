import {
    HStack,
    IconButton,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    Tooltip,
    useDisclosure,
    useToast,
    VStack,
} from "@chakra-ui/react";
import CustomButton from "components/CustomButton";
import FormRemoteSelectInput, {
    SelectOptionType,
} from "components/FormRemoteSelectInput";
import InputForm from "components/InputForm";
import InputMaskForm from "components/InputMask";
import useAuth from "contexts/useAuth";
import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { FaEdit } from "react-icons/fa";
import {
    editTerminal,
    findAllClients,
    findAllCompanys,
} from "services/api.service";
import { validCpf } from "services/validDocument";
import { onlyNumbers } from "utils/number";
import { TerminalCompany } from "./Terminal.type";

const EditTerminal: FC<{
  terminal: TerminalCompany;
  refetch: () => void;
}> = ({ terminal, refetch }) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [companyId, setCompanyId] = React.useState<string | undefined>(
    undefined
  );
  const { userData } = useAuth();
  const [loading, setLoading] = React.useState(false);
  const { control, handleSubmit, setValue, reset } = useForm<{
    customer: SelectOptionType;
    company: SelectOptionType;
    name: string;
    email: string;
    document: string;
  }>();

  React.useEffect(() => {
    if (isOpen) {
      setCompanyId(terminal.companyId);
      reset({
        name: terminal.name,
        email: terminal.email,
        document: terminal.document,
        company: {
          label: terminal.company.name,
          value: terminal.company.id,
        },
        customer: {
          label: terminal.customer.name,
          value: terminal.customer.id,
        },
      });
    }
  }, [terminal, isOpen, reset]);

  const save = async (dataForm: {
    customer: SelectOptionType;
    company: SelectOptionType;
    name: string;
    email: string;
    document: string;
  }) => {
    setLoading(true);
    editTerminal(terminal.id, {
      customerId: dataForm?.customer?.value,
      companyId,
      name: dataForm.name,
      document: onlyNumbers(dataForm.document),
      email: dataForm.email,
    })
      .then(() => {
        setLoading(false);
        onClose();
        refetch();
        toast({
          title: "Sucesso",
          description: "Terminal alterado com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        setLoading(false);
        refetch();
        toast({
          title: "Erro",
          description:
            err?.response?.data?.message || "Erro ao alterar terminal",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      <Tooltip label="Editar terminal" aria-label="Visualizar">
        <IconButton
          onClick={onOpen}
          icon={<FaEdit />}
          aria-label="Editar"
          size="sm"
          rounded="md"
          variant="outline"
        />
      </Tooltip>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Editar Terminal</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={5}>
                <Text>
                  O terminal é um equipamento que será vinculado a um cliente.
                  <br />a autenticação será feita através do email e do CPF do
                  cliente.
                </Text>

                <InputForm
                  control={control}
                  name="name"
                  label="Nome"
                  type="text"
                  placeholder="Nome"
                  rules={{ required: "Campo obrigatório" }}
                />
                <InputForm
                  control={control}
                  name="email"
                  label="E-mail"
                  type="email"
                  placeholder="E-mail"
                  rules={{ required: "Campo obrigatório" }}
                />
                <InputMaskForm
                  mask="999.999.999-99"
                  control={control}
                  label="CPF"
                  name="document"
                  size="sm"
                  isRequired={true}
                  type="tel"
                  placeholder="CPF"
                  rules={{
                    validate: (value) => {
                      if (!value) return false;

                      return validCpf(onlyNumbers(value)) || "CPF inválido";
                    },
                  }}
                  required
                />

                {!userData.isCustomerAdmin && (
                  <FormRemoteSelectInput
                    control={control}
                    name="company"
                    registerOptions={{
                      required: "Campo obrigatório",
                    }}
                    label="Empresa"
                    onChange={(value) => {
                      setValue("company", value);
                      setCompanyId(value?.value);
                    }}
                    loadDataFn={({ value, cb }) =>
                      findAllCompanys({
                        filter: value,
                        limit: 20,
                        page: 0,
                        companyId: companyId,
                      }).then((retorno) => {
                        if (retorno?.registers?.length > 0) {
                          cb(
                            retorno.registers?.map((d: any) => ({
                              label: d.name,
                              value: d.id,
                            }))
                          );
                        } else {
                          cb([]);
                        }
                      })
                    }
                  />
                )}
                {(companyId || userData.isCustomerAdmin) && (
                  <FormRemoteSelectInput
                    control={control}
                    label="Cliente"
                    isDisabled={!companyId && !userData.isCustomerAdmin}
                    filters={{
                      companyId,
                    }}
                    loadDataFn={({ value, cb }) =>
                      findAllClients({ filter: value, companyId }).then(
                        (retorno) => {
                          if (retorno?.registers?.length > 0) {
                            cb(
                              retorno.registers?.map((d: any) => ({
                                label: d.name,
                                value: d.id,
                              }))
                            );
                          } else {
                            cb([]);
                          }
                        }
                      )
                    }
                    name="customer"
                  />
                )}
              </VStack>
            </ModalBody>
            <ModalFooter>
              <HStack spacing={4}>
                <CustomButton
                  colorScheme="red"
                  isLoading={loading}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancelar
                </CustomButton>
                <CustomButton
                  colorScheme="green"
                  isLoading={loading}
                  onClick={handleSubmit(save)}
                >
                  Adicionar
                </CustomButton>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default EditTerminal;
