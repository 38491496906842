import api from "services/api.service";
import { ListPointsEye } from "./types/Api";
import { ClientEye, ItemGroupMenu, ItemMenu } from "./types/ResponseEyeClient";

export const findAllEyeEvents = async (filter: {
  integrationId: string;
  page: number;
  limit: number;
}) => {
  return api
    .get("eye-mobile/events", { params: filter })
    .then((res) => res.data);
};

export const findAllEyeProductGroups = async (filter: {
  integrationId: string;
  page: number;
  limit: number;
  filter: string;
}) => {
  return api
    .get("eye-mobile/product_groups", { params: filter })
    .then((res) => res.data);
};

export const findAllPayTypes = async (filter: {
  integrationId: string;
  page: number;
  limit: number;
}) => {
  return api
    .get("eye-mobile/pay_types", { params: filter })
    .then((res) => res.data);
};
export const findAllEyePoints = async (filter: {
  integrationId: string;
  page: number;
  limit: number;
}) => {
  return api
    .get<ListPointsEye[]>("eye-mobile/points", { params: filter })
    .then((res) => res.data);
};

export const createLinkEye = async (data: {
  integrationId: string;
  pointId: string;
  customerId: string;
  eventId?: string;
  payTypes: Array<string | number>;
  menuId: number;
  menuName: string;
}) => {
  return api.post<{}>("eye-mobile/link", data).then((res) => res.data);
};

export const removeLinkEye = async (id: number) => {
  return api.delete<{}>(`eye-mobile/link/${id}`).then((res) => res.data);
};

export const listEyeClients = async (id: number) => {
  return api
    .get<ClientEye[]>(`eye-mobile/${id}/clients`)
    .then((res) => res.data);
};

export const findAllEyeMenus = async (filter: {
  integrationId: string;
  page: number;
  limit: number;
}) => {
  return api
    .get<ItemMenu[]>("eye-mobile/menus", { params: filter })
    .then((res) => res.data);
};

export const findMenuProductGroups = async (
  menuId: number,
  filter: {
    integrationId: string;
    page: number;
    limit: number;
  }
) => {
  return api
    .get<ItemGroupMenu[]>(`eye-mobile/menus/${menuId}/product_groups`, {
      params: filter,
    })
    .then((res) => res.data);
};

export const addClientEye = async (data: {
  eyeIntegrationId: number;
  customerId: string;
  productGroupId: number;
  productName: string;
  id?: number;
  splitPercentage: number;
}) => {
  return api.post<{}>("eye-mobile/client", data).then((res) => res.data);
};

export const removeClientEye = async (id: string) => {
  return api.delete<{}>(`eye-mobile/client/${id}`).then((res) => res.data);
};

export const removeClientFromRecurrence = async (id: string) => {
  return api.delete(`recurrences/clients/${id}`).then((res) => res.data);
};
