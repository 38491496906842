import {
    Button,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure,
    useToast,
    VStack,
} from "@chakra-ui/react";
import CustomButton from "components/CustomButton";
import FormRemoteSelectInput, { SelectOptionType } from "components/FormRemoteSelectInput";
import InputForm from "components/InputForm";
import InputNumber from "components/InputNumber";
import SelectForm from "components/SelectForm";
import { queryClient } from "index";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FaPlus } from "react-icons/fa";
import { createRecurrency, findAllClients } from "services/api.service";
import {
    RecurrencyPeriod,
    RecurrencyPeriodDescription,
} from "./recurrency-type";

export type CreateRecurrencyForm = {
  amount: string;
  description: string;
  period: string;
  customer: SelectOptionType;
};

const CreatePlan: React.FC<{}> = () => {
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { handleSubmit, control, reset } = useForm<CreateRecurrencyForm>();

  const save = async (dataForm: CreateRecurrencyForm) => {
    setLoading(true);
    createRecurrency({
      customerId: dataForm.customer.value,
      amount: dataForm.amount,
      description: dataForm.description,
      period: dataForm.period,
    })
      .then(() => {
        onClose();
        queryClient.refetchQueries({
          queryKey: ["findAllRecurrencys"],
          type: "active",
        });
        toast({
          title: "Sucesso",
          description: "Recorrência adicionado com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: "Erro",
          description:
            err?.response?.data?.message || "Erro ao criar Recorrência",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    reset({
      period: RecurrencyPeriod.ONCE,
    });
  }, [isOpen]);

  return (
    <>
      <CustomButton
        onClick={() => {
          onOpen();
          reset();
        }}
        variant="outline"
        colorScheme="brand"
        leftIcon={<FaPlus />}
      >
        <Text>Criar produto</Text>
      </CustomButton>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Criar Produto</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={3}>
                <InputForm
                  control={control}
                  name="description"
                  label="Nome do produto *"
                  placeholder="Ex: Produto 1"
                  type="text"
                  rules={{
                    required: "Campo obrigatório",
                  }}
                />
                <InputNumber
                  control={control}
                  name="amount"
                  label="Valor *"
                  placeholder="Ex: R$ 100,00"
                  rules={{
                    required: "Campo obrigatório",
                  }}
                />

                <SelectForm
                  control={control}
                  name="period"
                  label="Período da recorrência *"
                  rules={{
                    required: "Campo obrigatório",
                  }}
                  options={Object.values(RecurrencyPeriod).map((period) => ({
                    label: RecurrencyPeriodDescription[period],
                    value: period,
                  }))}
                />
                <FormRemoteSelectInput
                  control={control}
                  name="customer"
                  label="Cliente Vinculado"
                  size="sm"
                  registerOptions={{
                    required: "Campo obrigatório",
                  }}
                  loadDataFn={({ value, cb }) =>
                    findAllClients({
                      filter: value,
                      limit: 20,
                      page: 0,
                    }).then((retorno) => {
                      if (retorno?.registers?.length > 0) {
                        cb([
                          { label: "Escolher uma opção...", value: "" },
                          ...retorno.registers?.map((d: any) => ({
                            label: d.name,
                            value: d.id,
                          })),
                        ]);
                      } else {
                        cb([]);
                      }
                    })
                  }
                />
              </VStack>
            </ModalBody>
            <ModalFooter>
              <HStack spacing={4}>
                <Button
                  colorScheme="red"
                  isLoading={loading}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  colorScheme="green"
                  isLoading={loading}
                  onClick={handleSubmit(save)}
                >
                  Adicionar
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default CreatePlan;
