import {
    FormControl,
    FormErrorMessage,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    useToast,
    VStack
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { Select } from 'chakra-react-select';
import CustomButton from 'components/CustomButton';
import { queryClient } from 'index';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FaPlus } from 'react-icons/fa';
import { addPlanToRecurrencyLink, findAllRecurrences } from 'services/api.service';

interface AddPlanToLinkProps {
  linkId: string;
}

type FormData = {
  planId: string;
};

const AddPlanToLink: React.FC<AddPlanToLinkProps> = ({ linkId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const { control, handleSubmit, reset } = useForm<FormData>();

  const { data: recurrences } = useQuery({
    queryKey: ['findAllRecurrences'],
    queryFn: () =>
      findAllRecurrences({
        limit: 200,
        page: 0,
        filter: '',
      }),
  });

  const plansOptions = useMemo(() => {
    if (!recurrences?.registers) return [];
    return recurrences.registers.map((plan) => ({
      label: `${plan.description} - R$ ${plan.amount}`,
      value: plan.id,
    }));
  }, [recurrences]);

  const handleAddPlan = async (data: FormData) => {
    try {
      // TODO: Implementar chamada à API para adicionar plano ao link
      await addPlanToRecurrencyLink(linkId, { planId: data.planId });
      queryClient.invalidateQueries(['findRecurrencyLink', linkId]);
      toast({
        title: 'Sucesso',
        description: 'Plano adicionado com sucesso',
        status: 'success',
      });
      onClose();
      reset();
    } catch (error: any) {
      toast({
        title: 'Erro',
        description: error?.response?.data?.message || 'Erro ao adicionar plano',
        status: 'error',
      });
    }
  };

  return (
    <>
      <CustomButton
        leftIcon={<FaPlus />}
        colorScheme="green"
        onClick={onOpen}
      >
        Adicionar Plano
      </CustomButton>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Adicionar Plano ao Link</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <VStack spacing={4}>
              <FormControl>
                <FormLabel>Plano *</FormLabel>
                <Controller
                  control={control}
                  name="planId"
                  rules={{ required: 'Selecione um plano' }}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <Select
                        options={plansOptions}
                        onChange={(newValue: any) => field.onChange(newValue.value)}
                        value={plansOptions.find((option) => option.value === field.value)}
                        placeholder="Selecione um plano"
                      />
                      {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                    </>
                  )}
                />
              </FormControl>

              <CustomButton
                colorScheme="green"
                onClick={handleSubmit(handleAddPlan)}
              >
                Adicionar
              </CustomButton>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddPlanToLink;
