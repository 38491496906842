import {
    Button,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure,
    useToast,
    VStack,
} from "@chakra-ui/react";
import FormRemoteSelectInput from "components/FormRemoteSelectInput";
import { queryClient } from "index";
import React from "react";
import { useForm } from "react-hook-form";
import { FaPlus } from "react-icons/fa";
import {
    createIntegrationToCompany,
    findAllCompanys,
} from "services/api.service";

const IntegrationToCompany: React.FC<{
  integrationId: string;
}> = ({ integrationId }) => {
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { handleSubmit, control, reset } = useForm<{
    company: { label: string; value: string };
  }>();

  const save = async (dataForm: {
    company: { label: string; value: string };
  }) => {
    setLoading(true);
    createIntegrationToCompany({
      companyId: dataForm.company.value,
      integrationId,
    })
      .then(() => {
        onClose();
        queryClient.refetchQueries({
          queryKey: ["findAllIntegrations"],
          type: "active",
        });
        toast({
          title: "Sucesso",
          description: "Integração adicionada com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: "Erro",
          description:
            err?.response?.data?.message || "Erro ao criar integração",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Button
        onClick={() => {
          onOpen();
          reset();
        }}
        size="sm"
        rounded="sm"
        variant="outline"
        colorScheme="brand"
      >
        <FaPlus />
        <Text ml={2}>Adicionar Empresa</Text>
      </Button>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Adicionar Integração a Empresa</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={3}>
                <FormRemoteSelectInput
                  control={control}
                  name="company"
                  registerOptions={{
                    required: "Campo obrigatório",
                  }}
                  label="Empresa"
                  loadDataFn={({ value, cb }) =>
                    findAllCompanys({ filter: value, limit: 20, page: 0 }).then(
                      (retorno) => {
                        if (retorno?.registers?.length > 0) {
                          cb(
                            retorno.registers?.map((d: any) => ({
                              label: d.name,
                              value: d.id,
                            }))
                          );
                        } else {
                          cb([]);
                        }
                      }
                    )
                  }
                />
              </VStack>
            </ModalBody>
            <ModalFooter>
              <HStack spacing={4}>
                <Button
                  colorScheme="red"
                  isLoading={loading}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  colorScheme="green"
                  isLoading={loading}
                  onClick={handleSubmit(save)}
                >
                  Adicionar
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default IntegrationToCompany;
