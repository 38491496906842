import {
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tooltip,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import CustomButton from "components/CustomButton";
import InputForm from "components/InputForm";
import { DateTime } from "luxon";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FaSync } from "react-icons/fa";
import {
  syncConciliationIntegration,
  syncPaymentsSicoob,
} from "services/api.service";

export interface Company {
  id: string;
  name: string;
  document: string;
  status: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface IntegrationResponse {
  id: string;
  name: string;
  type: string;
  user: string;
  createdAt: Date;
  identifierUrl: string;
  active: boolean;
}

const SicoobIntegration: React.FC<{ data: IntegrationResponse }> = ({
  data,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const { control, handleSubmit, setValue } = useForm<{
    startDate: string;
    endDate: string;
  }>({
    defaultValues: {
      startDate: new Date(
        new Date().setDate(new Date().getDate() - 2)
      ).toISOString(),
      endDate: new Date().toISOString(),
    },
  });

  const handleProcessOthers = (params: {
    startDate: string;
    endDate: string;
  }) => {
    const startDate = DateTime.fromISO(params.startDate).toISO();
    const endDate = DateTime.fromISO(params.endDate).toISO();
    syncConciliationIntegration(data.id, {
      startDate: startDate,
      endDate: endDate,
    })
      .then(() => {
        toast({
          title: "Processando",
          description: "Sincronizando transações",
          status: "info",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: "Erro ao processar lote",
          description: err.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  useEffect(() => {
    if (isOpen) {
      setValue(
        "startDate",
        DateTime.now().minus({ days: 2 }).toFormat("yyyy-MM-ddTHH:mm")
      );
      setValue("endDate", DateTime.now().toFormat("yyyy-MM-ddTHH:mm"));
    }
  }, [isOpen]);

  const handleProcess = () => {
    syncPaymentsSicoob(data.id)
      .then(() => {
        toast({
          title: "Processando",
          description: "Processando lote de boletos",
          status: "info",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: "Erro ao processar lote",
          description: err.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      {(data.type === "SICOOB" || data.type === "PAG_SEGURO" || data.type === "EYE_MOBILE") && (
        <Tooltip label="SINCRONIZAR TRANSAÇÕES">
          <IconButton
            aria-label="Apertar"
            rounded="md"
            isDisabled={!data.identifierUrl}
            size="sm"
            variant="outline"
            icon={<FaSync />}
            onClick={onOpen}
          />
        </Tooltip>
      )}
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Sincronizar Transações</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {data.type === "SICOOB" ? (
                <VStack mb={5} spacing={3}>
                  <Button colorScheme="brand" onClick={handleProcess}>
                    Processar últimos 2 dias
                  </Button>
                </VStack>
              ) : (
                <VStack mb={5} spacing={3}>
                  <InputForm
                    name="startDate"
                    label="Data de início"
                    type="datetime-local"
                    control={control}
                    rules={{
                      required: "Data de início é obrigatória",
                    }}
                  />
                  <InputForm
                    name="endDate"
                    label="Data de fim"
                    type="datetime-local"
                    control={control}
                    rules={{
                      required: "Data de fim é obrigatória",
                    }}
                  />
                  <CustomButton
                    colorScheme="brand"
                    onClick={handleSubmit(handleProcessOthers)}
                  >
                    Processar
                  </CustomButton>
                </VStack>
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default SicoobIntegration;
