import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useToast,
    VStack,
} from "@chakra-ui/react";
import FormRemoteSelectInput, {
    SelectOptionType,
} from "components/FormRemoteSelectInput";
import InputForm from "components/InputForm";
import useAuth from "contexts/useAuth";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
    createUser,
    findAllCompanys,
    findAllUsersGroups,
} from "services/api.service";

interface RegisterUserProps {
  name: string;
  email: string;
  password: string;
  phone: string;
  company: SelectOptionType;
  groupId: SelectOptionType;
}

const RegisterUser: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const toast = useToast();
  const {
    userData: { isCustomerAdmin },
  } = useAuth();

  const { control, reset, handleSubmit } = useForm<RegisterUserProps>();

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  const handleSave = (data: RegisterUserProps) => {
    createUser({
      ...data,
      companyId: data.company?.value,
      groupId: data.groupId?.value,
    })
      .then(() => {
        onClose();
      })
      .catch((err) => {
        toast({
          title: "Error",
          description: err.response.data.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Criar usuário</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={3}>
            <InputForm
              control={control}
              label="Nome"
              name="name"
              placeholder="Nome"
              required
              type="text"
              rules={{
                required: "Campo obrigatório",
              }}
            />
            <InputForm
              control={control}
              label="Email"
              name="email"
              placeholder="Email"
              required
              type="email"
              rules={{
                required: "Campo obrigatório",
              }}
            />
            <InputForm
              control={control}
              label="Telefone"
              name="phone"
              placeholder="Telefone"
              required
              type="tel"
              rules={{
                minLength: {
                  value: 11,
                  message: "Mínimo de 11 caracteres",
                },

                maxLength: {
                  value: 11,
                  message: "Máximo de 11 caracteres",
                },
                required: "Campo obrigatório",
              }}
            />
            <InputForm
              control={control}
              label="Senha"
              name="password"
              placeholder="Senha"
              required
              type="password"
              rules={{
                required: "Campo obrigatório",
              }}
            />
            {!isCustomerAdmin && (
              <FormRemoteSelectInput
                control={control}
                name="company"
                registerOptions={{
                  required: "Campo obrigatório",
                }}
                label="Empresa"
                loadDataFn={({ value, cb }) =>
                  findAllCompanys({ filter: value, limit: 20, page: 0 }).then(
                    (retorno) => {
                      if (retorno?.registers?.length > 0) {
                        cb(
                          retorno.registers?.map((d: any) => ({
                            label: d.name,
                            value: d.id,
                          }))
                        );
                      } else {
                        cb([]);
                      }
                    }
                  )
                }
              />
            )}

            <FormRemoteSelectInput
              control={control}
              label="Grupo de permissão"
              loadDataFn={({ value, cb }) =>
                findAllUsersGroups(value).then((retorno) => {
                  if (retorno.registers?.length > 0) {
                    cb(
                      retorno.registers.map((d: any) => ({
                        label: d.name,
                        value: d.id,
                      }))
                    );
                  } else {
                    cb([]);
                  }
                })
              }
              required
              name="groupId"
            />
            <Button
              onClick={handleSubmit(handleSave)}
              variant={"outline"}
              w={`100%`}
              color={"green"}
            >
              Salvar
            </Button>
          </VStack>
        </ModalBody>

        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RegisterUser;
