import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    useToast,
    VStack,
} from "@chakra-ui/react";
import FormRemoteSelectInput, {
    SelectOptionType,
} from "components/FormRemoteSelectInput";
import InputForm from "components/InputForm";
import useAuth from "contexts/useAuth";
import { queryClient } from "index";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FaPencilAlt } from "react-icons/fa";
import {
    findAllCompanys,
    findAllUsersGroups,
    updateUser,
} from "services/api.service";

interface RegisterUserProps {
  name: string;
  email: string;
  password: string;
  phone: string;
  company: SelectOptionType;
  groupId: SelectOptionType;
}

const EditUser: React.FC<{
  data: any;
}> = ({ data }) => {
  const toast = useToast();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    userData: { isCustomerAdmin },
  } = useAuth();

  const { control, reset, handleSubmit } = useForm<RegisterUserProps>({
    defaultValues: {
      company: {
        label: data?.Company?.name || "",
        value: data?.Company?.id || "",
      },
      groupId: {
        label: data?.Groups?.name || "",
        value: data?.Groups?.id || "",
      },
      email: data.email,
      name: data.name,
      phone: data.phone,
    },
  });

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  const handleSave = (dataUpdate: RegisterUserProps) => {
    if (!dataUpdate.password) {
      delete dataUpdate.password;
    }
    updateUser(data.id, {
      ...dataUpdate,
      companyId: dataUpdate.company?.value,
      groupId: dataUpdate.groupId?.value,
    })
      .then(() => {
        onClose();
        queryClient.refetchQueries({
          queryKey: ["users"],
          type: "active",
        });
      })
      .catch((err) => {
        toast({
          title: "Error",
          description: err.response.data.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      <Button
        onClick={onOpen}
        size="sm"
        rounded="sm"
        variant="outline"
        colorScheme="brand"
      >
        <FaPencilAlt />
      </Button>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Criar usuário</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={3}>
                <InputForm
                  control={control}
                  label="Nome"
                  name="name"
                  placeholder="Nome"
                  required
                  type="text"
                  rules={{
                    required: "Campo obrigatório",
                  }}
                />
                <InputForm
                  control={control}
                  label="Email"
                  name="email"
                  placeholder="Email"
                  required
                  type="email"
                  rules={{
                    required: "Campo obrigatório",
                  }}
                />
                <InputForm
                  control={control}
                  label="Telefone"
                  name="phone"
                  placeholder="Telefone"
                  required
                  type="tel"
                  rules={{
                    minLength: {
                      value: 11,
                      message: "Mínimo de 11 caracteres",
                    },

                    maxLength: {
                      value: 11,
                      message: "Máximo de 11 caracteres",
                    },
                    required: "Campo obrigatório",
                  }}
                />
                <InputForm
                  control={control}
                  label="Senha"
                  name="password"
                  placeholder="Senha(Opcional)"
                  type="password"
                />
                {!isCustomerAdmin && (
                  <FormRemoteSelectInput
                    control={control}
                    name="company"
                    registerOptions={{
                      required: "Campo obrigatório",
                    }}
                    label="Empresa"
                    loadDataFn={({ value, cb }) =>
                      findAllCompanys({
                        filter: value,
                        limit: 20,
                        page: 0,
                      }).then((retorno) => {
                        if (retorno?.registers?.length > 0) {
                          cb(
                            retorno.registers?.map((d: any) => ({
                              label: d.name,
                              value: d.id,
                            }))
                          );
                        } else {
                          cb([]);
                        }
                      })
                    }
                  />
                )}
                <FormRemoteSelectInput
                  control={control}
                  label="Grupo de permissão"
                  loadDataFn={({ value, cb }) =>
                    findAllUsersGroups(value).then((retorno) => {
                      if (retorno.registers?.length > 0) {
                        cb(
                          retorno?.registers?.map((d: any) => ({
                            label: d.name,
                            value: d.id,
                          }))
                        );
                      } else {
                        cb([]);
                      }
                    })
                  }
                  required
                  name="groupId"
                />
                <Button
                  onClick={handleSubmit(handleSave)}
                  variant={"outline"}
                  w={`100%`}
                  color={"green"}
                >
                  Salvar
                </Button>
              </VStack>
            </ModalBody>

            <ModalFooter></ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default EditUser;
