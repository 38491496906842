import { EditIcon } from "@chakra-ui/icons";
import {
    Alert,
    Badge,
    Box,
    Button,
    IconButton,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    SimpleGrid,
    Text,
    Tooltip,
    useDisclosure,
    useToast,
    VStack,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import FormRemoteSelectInput, {
    SelectOptionType,
} from "components/FormRemoteSelectInput";
import InputForm from "components/InputForm";
import useAuth from "contexts/useAuth";
import { queryClient } from "index";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { BsEye } from "react-icons/bs";
import { findAllCompanys, findPlan, updatePlan } from "services/api.service";
import { OriginTaxesDescription, TaxesOriginType, TaxesType } from "types/Fees";
import { maskCurrency } from "utils/number";
import EditFee from "./EditFee";
export interface EditPlanForm {
  description: string;
  name: string;
  company?: SelectOptionType;
}

const EditPlan: React.FC<{ id: number; readOnly: boolean }> = ({
  id,
  readOnly,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { userData } = useAuth();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { control, handleSubmit, setValue } = useForm<EditPlanForm>({
    defaultValues: {},
  });

  const { data: plan } = useQuery(["findPlan", id], () => findPlan(id), {
    enabled: !!id && isOpen,
  });

  const toast = useToast();

  const onSubmit = (data: EditPlanForm) => {
    setIsLoading(true);
    updatePlan(plan.id, {
      ...data,
      companyId: data.company?.value,
    })
      .then(() => {
        queryClient.refetchQueries(["findAllPlans"]);
        toast({
          title: "Sucesso",
          description: "Plano atualizado com sucesso",
          status: "success",
          duration: 5000,
          position: "top",
          isClosable: true,
        });
        onClose();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (plan) {
      setValue("name", plan.name);
      setValue("description", plan.description);
      setValue("company", {
        label: plan.company?.name,
        value: plan.companyId,
      });
    }
  }, [plan, setValue]);

  return (
    <>
      <Tooltip
        label={readOnly ? "Visualizar" : "Editar plano"}
        aria-label="Visualizar"
      >
        <IconButton
          aria-label="Editar plano"
          rounded="md"
          size="sm"
          variant="outline"
          icon={readOnly ? <BsEye /> : <EditIcon />}
          onClick={() => {
            onOpen();
          }}
        />
      </Tooltip>
      {isOpen && plan && (
        <>
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent minW={{ lg: "1100px", md: "700px", sm: "400px" }}>
              <ModalHeader>
                {readOnly ? "Visualizar" : "Editar"} Plano
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <VStack spacing={3}>
                    {!readOnly && !userData.isCustomerAdmin && (
                      <FormRemoteSelectInput
                        control={control}
                        name="company"
                        label="Empresa (Opcional)"
                        loadDataFn={({ value, cb }) =>
                          findAllCompanys({
                            filter: value,
                            limit: 20,
                            page: 0,
                          }).then((retorno) => {
                            if (retorno?.registers?.length > 0) {
                              cb([
                                { label: "Escolher uma opção...", value: "" },
                                ...retorno.registers?.map((d: any) => ({
                                  label: d.name,
                                  value: d.id,
                                })),
                              ]);
                            } else {
                              cb([]);
                            }
                          })
                        }
                      />
                    )}
                    <InputForm
                      control={control}
                      isDisabled={readOnly}
                      name="name"
                      type="text"
                      label="Nome"
                      rules={{ required: "Campo obrigatório" }}
                    />
                    <InputForm
                      control={control}
                      isDisabled={readOnly}
                      name="description"
                      type="text"
                      label="Descrição"
                      rules={{ required: "Campo obrigatório" }}
                    />
                  </VStack>
                  {!readOnly && (
                    <Button
                      mt={5}
                      type="submit"
                      variant={"outline"}
                      isLoading={isLoading}
                      isDisabled={readOnly}
                      w={`100%`}
                      color={"green"}
                    >
                      Salvar
                    </Button>
                  )}
                  <Text my={5} fontSize={18} fontWeight="bold">
                    Configuração das Taxas
                  </Text>
                  <SimpleGrid columns={{ md: 2, sm: 1 }} spacing={3}>
                    {plan.fees
                      .filter((fee) => fee.origin !== "CREDIT_PARCELLED")
                      .map((fee) => {
                        const withInstallmentsConfigure = [
                          TaxesOriginType.CREDIT_ELO,
                          TaxesOriginType.CREDIT_VISA_MASTER,
                          TaxesOriginType.CREDIT_CARD,
                          TaxesOriginType.INTEGRATION_CREDIT_CARD,
                        ].includes(fee.origin as TaxesOriginType);

                        const needConfigure =
                          withInstallmentsConfigure && !fee.installments;

                        return (
                          <Box
                            key={fee.id}
                            border={"1px solid #e2e2e2"}
                            padding={2}
                            backgroundColor={needConfigure ? "red.50" : "white"}
                          >
                            <Text fontWeight="bold" fontSize={16} mb={3}>
                              {OriginTaxesDescription[fee.origin]}
                            </Text>
                            {needConfigure ? (
                              <Alert status="info">
                                <Text>
                                  Taxa não configurada para esse plano
                                </Text>
                              </Alert>
                            ) : (
                              <></>
                            )}
                            <SimpleGrid columns={{ md: 2, sm: 1 }} spacing={3}>
                              <Text fontSize={16}>
                                {withInstallmentsConfigure
                                  ? "Valor à vista: "
                                  : "Valor da taxa: "}
                                <b>
                                  {fee.type === "FIXED"
                                    ? maskCurrency(fee.amount)
                                    : `${
                                        withInstallmentsConfigure
                                          ? fee.installments?.["1"]?.fee
                                          : fee.amount
                                      }%`}
                                </b>
                              </Text>

                              <Text fontSize={16}>
                                Tipo:{" "}
                                <b>
                                  {fee.type === "FIXED"
                                    ? "Fixa"
                                    : "Porcentagem"}
                                </b>
                              </Text>
                              <Text fontSize={16}>
                                Valor fixo mínimo:{" "}
                                <b>{maskCurrency(fee.minFixed)}</b>
                              </Text>

                              <Text fontSize={16}>
                                Valor fixo máximo:{" "}
                                <b>{maskCurrency(fee.maxFixed)}</b>
                              </Text>

                              <Text fontSize={16}>
                                Dias para liberar:{" "}
                                <b>
                                  {fee.releaseInDays} dia
                                  {fee.releaseInDays > 1 ? "s" : ""}
                                </b>
                              </Text>

                              {fee.antecipationActive ? (
                                <Text fontSize={16} color={"green.800"}>
                                  Antecipação: <b>Ativa</b> -{" "}
                                  <Badge colorScheme="green">
                                    {fee.antecipationPercent}%
                                  </Badge>
                                </Text>
                              ) : (
                                <Text fontSize={16} color={"red.800"}>
                                  Antecipação: <b>Inativa</b>
                                </Text>
                              )}
                            </SimpleGrid>
                            {!readOnly && (
                              <EditFee fee={fee} disabled={readOnly} />
                            )}
                          </Box>
                        );
                      })}

                    {Object.keys(TaxesOriginType).map((paymentMethod) => {
                      if (
                        plan.fees.find(
                          (fee) =>
                            fee.origin === (paymentMethod as TaxesOriginType)
                        )
                      ) {
                        return null;
                      }
                      return (
                        <Box
                          key={paymentMethod}
                          border={"1px solid #e2e2e2"}
                          padding={2}
                        >
                          <Text fontWeight="bold" fontSize={16} mb={3}>
                            {OriginTaxesDescription[paymentMethod]}
                          </Text>
                          <SimpleGrid columns={{ md: 1, sm: 1 }} spacing={3}>
                            <Alert status="warning">
                              <Text>Taxa não configurada para esse plano</Text>
                            </Alert>
                          </SimpleGrid>
                          {!readOnly && (
                            <EditFee
                              key={paymentMethod}
                              fee={{
                                amount: "0",
                                maxFixed: "0",
                                minFixed: "0",
                                origin: paymentMethod as TaxesOriginType,
                                releaseInDays: 0,
                                type: TaxesType.PERCENTUAL,
                                antecipationActive: false,
                                antecipationPercent: "0",
                                planId: plan.id,
                                createdAt: DateTime.now().toISO(),
                                updatedAt: DateTime.now().toISO(),
                                installments: {},
                                installmentsType: "FROM_CUSTOMER",
                                maxInstallments: 12,
                              }}
                              disabled={readOnly}
                            />
                          )}
                        </Box>
                      );
                    })}
                  </SimpleGrid>
                </form>
              </ModalBody>

              <ModalFooter></ModalFooter>
            </ModalContent>
          </Modal>
        </>
      )}
    </>
  );
};

export default EditPlan;
