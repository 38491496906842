import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    SimpleGrid,
    Text,
    useDisclosure,
    useToast,
    VStack,
} from "@chakra-ui/react";
import FormRemoteSelectInput, {
    SelectOptionType,
} from "components/FormRemoteSelectInput";
import useAuth from "contexts/useAuth";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FaPen } from "react-icons/fa";
import {
    findAllCompanys,
    findAllPlans,
    findCep,
    findCnpj,
    updateCustomer,
} from "services/api.service";
import { validCnpj, validCpf } from "services/validDocument";
import { CustomerResponse } from "types/Customers";
import { onlyNumbers } from "utils/number";

export interface EditCustomerForm {
  name: string;
  nameSecond: string;
  email: string;
  company: SelectOptionType;
  plan: SelectOptionType;
  taxes: any;
  prazos: any;
  password: string;
  document: string;
  phone?: string;
  address: {
    cep: string;
    state: string;
    city: string;
    street: string;
    number: string;
    complement: string;
    neighborhood: string;
    cepId: number;
  };
}

const EditCustomer: React.FC<{
  data: CustomerResponse;
  refetch: () => void;
}> = ({ data: customer, refetch }) => {
  const toast = useToast();
  const {
    userData: { isCustomerAdmin },
  } = useAuth();
  const { isOpen, onClose: onCloseSafe, onOpen } = useDisclosure();
  const {
    register,
    setValue,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<EditCustomerForm>();

  const onClose = () => {
    onCloseSafe();
  };

  useEffect(() => {
    if (customer) {
      const address = customer.CustomerAddress?.[0];
      setValue("nameSecond", customer.name);
      setValue("name", customer.name);
      setValue("email", customer.email);
      setValue("company", {
        label: customer.Company?.name,
        value: customer.Company?.id,
      });
      setValue("plan", {
        label: customer.plan?.name,
        value: String(customer.plan?.id),
      });
      setValue("document", customer.document_number);
      setValue("phone", customer.CustomerContact?.[0]?.contact);
      setValue("address.street", address?.address);
      setValue("address.number", address?.number_address);
      setValue("address.complement", address?.complement);
      setValue("address.neighborhood", address?.neigherhood);
      setValue("address.cep", address?.Cep.cep);
      setValue("address.cepId", address?.cepId);
      // blurCepResponsable(address?.Cep.cep);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer, setValue]);

  const onSubmit = (dataUpdateCustomer: EditCustomerForm) => {
    updateCustomer(customer.id, {
      ...dataUpdateCustomer,
      id: customer.id,
      document: onlyNumbers(dataUpdateCustomer.document),
      planId: dataUpdateCustomer.plan?.value
        ? Number(dataUpdateCustomer.plan?.value)
        : undefined,
      companyId: dataUpdateCustomer.company?.value,
    })
      .then(() => {
        onClose();
        toast({
          title: "Sucesso",
          description: "Cliente atualizado com sucesso",
          status: "success",
          duration: 9000,
          isClosable: true,
        });

        refetch();
      })
      .catch((err) => {
        toast({
          title: "Error",
          description: err.response.data.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const blurCepResponsable = async (cep: string) => {
    cep = onlyNumbers(cep);
    if (cep?.match(/^\d{8}$/)) {
      findCep(cep)
        .then((response) => {
          setValue("address.cepId", response.id);
          setValue("address.cep", response.cep);
          setValue("address.state", response.state.name);
          setValue("address.city", response.City.name);
        })
        .catch((err) => {
          toast({
            title: "Error",
            description: err?.response?.data?.message,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        });
    }
  };

  const consultCnpj = async (cnpj: string) => {
    cnpj = onlyNumbers(cnpj);

    if (validCnpj(cnpj)) {
      findCnpj(cnpj)
        .then((response) => {
          setValue("nameSecond", response.estabelecimento.nome_fantasia);
          setValue("name", response.razao_social);
          setValue("email", response.estabelecimento.email);
          setValue(
            "phone",
            response.estabelecimento.ddd1 + response.estabelecimento.telefone1
          );
          setValue(
            "address.street",
            response.estabelecimento.tipo_logradouro +
              " " +
              response.estabelecimento.logradouro
          );
          setValue("address.number", response.estabelecimento.numero);
          setValue("address.complement", response.estabelecimento.complemento);
          setValue("address.neighborhood", response.estabelecimento.bairro);
          setValue("address.cep", response.estabelecimento.cep);
          setValue("address.state", response.estabelecimento.estado.nome);
          setValue("address.city", response.estabelecimento.cidade.nome);
          setValue("address.cepId", response.cepId);
        })
        .catch((err) => {
          toast({
            title: "Error",
            description: err?.response?.data?.message,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        });
    }
  };

  return (
    <>
      <Button
        onClick={onOpen}
        size="sm"
        rounded="sm"
        variant="outline"
        colorScheme="brand"
      >
        <FaPen />
        <Text ml={2}>Editar Cadastro</Text>
      </Button>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent minW={{ lg: "1200px", md: "700px", sm: "400px" }}>
            <ModalHeader>Editar Cliente</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <form onSubmit={handleSubmit(onSubmit)}>
                {!isCustomerAdmin && (
                  <FormRemoteSelectInput
                    control={control}
                    name="company"
                    registerOptions={{
                      required: "Campo obrigatório",
                    }}
                    label="Empresa"
                    loadDataFn={({ value, cb }) =>
                      findAllCompanys({
                        filter: value,
                        limit: 20,
                        page: 0,
                      }).then((retorno) => {
                        if (retorno?.registers?.length > 0) {
                          cb(
                            retorno.registers?.map((d: any) => ({
                              label: d.name,
                              value: d.id,
                            }))
                          );
                        } else {
                          cb([]);
                        }
                      })
                    }
                  />
                )}
                <FormRemoteSelectInput
                  control={control}
                  name="plan"
                  registerOptions={{
                    required: "Campo obrigatório",
                  }}
                  required
                  label="Plano"
                  loadDataFn={({ value, cb }) =>
                    findAllPlans({
                      filter: value,
                      limit: 20,
                      page: 0,
                    }).then((retorno) => {
                      if (retorno?.registers?.length > 0) {
                        cb(
                          retorno.registers?.map((d: any) => ({
                            label: d.name,
                            value: d.id,
                          }))
                        );
                      } else {
                        cb([]);
                      }
                    })
                  }
                />
                <SimpleGrid mb={5} columns={{ md: 2, sm: 1 }} spacing={3}>
                  <VStack>
                    <Text fontWeight="bold">Dados da empresa</Text>
                    <FormControl mt={5} isInvalid={!!errors?.document}>
                      <FormLabel display="flex" ms="4px" fontSize="sm">
                        CNPJ<Text>*</Text>
                      </FormLabel>
                      <Input
                        size="sm"
                        isRequired={true}
                        isInvalid={!!errors.document}
                        fontSize="sm"
                        name="document"
                        {...register("document", {
                          required: true,
                          validate: (value) => {
                            value = onlyNumbers(value);
                            if (validCnpj(value)) return true;
                            if (validCpf(value)) return true;
                            return "Não é válido";
                          },
                        })}
                        onBlur={(e) => {
                          consultCnpj(e.target.value);
                        }}
                        ms={{ base: "0px", md: "0px" }}
                        type="tel"
                        placeholder="Nome"
                        required
                      />
                      <FormErrorMessage>
                        {errors.document?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl mt={5} isInvalid={!!errors.email}>
                      <FormLabel display="flex" ms="4px" fontSize="sm">
                        E-mail<Text>*</Text>
                      </FormLabel>
                      <Input
                        size="sm"
                        fontSize="sm"
                        name="email"
                        {...register("email", {
                          required: "Esse campo é obrigatório",
                        })}
                        ms={{ base: "0px", md: "0px" }}
                        type="email"
                        autoComplete="email"
                        placeholder="mail@mail.com"
                        required
                      />
                      <FormErrorMessage>
                        {errors.email?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl mt={5} isInvalid={!!errors.name}>
                      <FormLabel display="flex" ms="4px" fontSize="sm">
                        Razão social<Text>*</Text>
                      </FormLabel>
                      <Input
                        size="sm"
                        isRequired={true}
                        isInvalid={!!errors.name}
                        fontSize="sm"
                        {...register("name", { required: true })}
                        ms={{ base: "0px", md: "0px" }}
                        type="text"
                        placeholder="Nome"
                        required
                      />
                      <FormErrorMessage>
                        {errors.name?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl mt={5} isInvalid={!!errors.nameSecond}>
                      <FormLabel display="flex" ms="4px" fontSize="sm">
                        Nome Fantasia<Text>*</Text>
                      </FormLabel>
                      <Input
                        size="sm"
                        isRequired={true}
                        isInvalid={!!errors.name}
                        fontSize="sm"
                        {...register("nameSecond", { required: true })}
                        ms={{ base: "0px", md: "0px" }}
                        type="text"
                        placeholder="Nome Fantasia"
                        required
                      />
                      <FormErrorMessage>
                        {errors.nameSecond?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl mt={5} isInvalid={!!errors.phone}>
                      <FormLabel display="flex" ms="4px" fontSize="sm">
                        Telefone<Text>*</Text>
                      </FormLabel>
                      <Input
                        size="sm"
                        isRequired={true}
                        fontSize="sm"
                        {...register("phone", {
                          required: true,
                          minLength: {
                            value: 11,
                            message: "Precisa ser um telefone valido",
                          },
                          maxLength: {
                            value: 11,
                            message: "Precisa ser um telefone valido",
                          },
                        })}
                        ms={{ base: "0px", md: "0px" }}
                        type="tel"
                        placeholder="Nome"
                        required
                      />
                      <FormErrorMessage>
                        {errors.phone?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </VStack>
                  <VStack>
                    <Text fontWeight="bold">Endereço da Empresa</Text>
                    <FormControl mt={5} isInvalid={!!errors.address?.cep}>
                      <FormLabel display="flex" ms="4px" fontSize="sm">
                        CEP<Text>*</Text>
                      </FormLabel>
                      <Input
                        size="sm"
                        isRequired={true}
                        fontSize="sm"
                        {...register("address.cep", {
                          required: true,
                          minLength: {
                            value: 8,
                            message: "Precisa ser um cep valido",
                          },
                          maxLength: {
                            value: 8,
                            message: "Precisa ser um cep valido",
                          },
                        })}
                        ms={{ base: "0px", md: "0px" }}
                        type="tel"
                        placeholder="CEP"
                        required
                        onBlur={(e) => {
                          blurCepResponsable(e.target.value);
                        }}
                      />
                      <FormErrorMessage>
                        {errors.address?.cep?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isDisabled={true}
                      mt={5}
                      isInvalid={!!errors.address?.state}
                    >
                      <FormLabel display="flex" ms="4px" fontSize="sm">
                        Estado<Text>*</Text>
                      </FormLabel>
                      <Input
                        size="sm"
                        isRequired={true}
                        fontSize="sm"
                        {...register("address.state", {
                          required: true,
                        })}
                        ms={{ base: "0px", md: "0px" }}
                        placeholder="Estado"
                        required
                      />
                      <FormErrorMessage>
                        {errors.address?.state?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isDisabled={true}
                      mt={5}
                      isInvalid={!!errors.address?.city}
                    >
                      <FormLabel display="flex" ms="4px" fontSize="sm">
                        Cidade<Text>*</Text>
                      </FormLabel>
                      <Input
                        size="sm"
                        isRequired={true}
                        fontSize="sm"
                        {...register("address.city", {
                          required: true,
                        })}
                        ms={{ base: "0px", md: "0px" }}
                        placeholder="Cidade"
                        required
                      />
                      <FormErrorMessage>
                        {errors.address?.city?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl mt={5} isInvalid={!!errors.address?.street}>
                      <FormLabel display="flex" ms="4px" fontSize="sm">
                        Rua<Text>*</Text>
                      </FormLabel>
                      <Input
                        size="sm"
                        isRequired={true}
                        fontSize="sm"
                        {...register("address.street", {
                          required: true,
                          maxLength: {
                            value: 80,
                            message: "Esse campo tem limite de 80 caracteres",
                          },
                        })}
                        ms={{ base: "0px", md: "0px" }}
                        placeholder="Rua"
                        required
                      />
                      <FormErrorMessage>
                        {errors.address?.street?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl mt={5} isInvalid={!!errors.address?.number}>
                      <FormLabel display="flex" ms="4px" fontSize="sm">
                        Número<Text>*</Text>
                      </FormLabel>
                      <Input
                        size="sm"
                        isRequired={true}
                        fontSize="sm"
                        {...register("address.number", {
                          required: true,
                          maxLength: {
                            value: 10,
                            message: "Esse campo tem limite de 10 caracteres",
                          },
                        })}
                        ms={{ base: "0px", md: "0px" }}
                        placeholder="Número"
                        required
                      />
                      <FormErrorMessage>
                        {errors.address?.number?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      mt={5}
                      isInvalid={!!errors.address?.complement}
                    >
                      <FormLabel display="flex" ms="4px" fontSize="sm">
                        Complemento<Text>*</Text>
                      </FormLabel>
                      <Input
                        size="sm"
                        isRequired={true}
                        fontSize="sm"
                        {...register("address.complement", {
                          required: true,
                          maxLength: {
                            value: 80,
                            message: "Esse campo tem limite de 80 caracteres",
                          },
                        })}
                        ms={{ base: "0px", md: "0px" }}
                        placeholder="Complemento"
                        required
                      />
                      <FormErrorMessage>
                        {errors.address?.complement?.message}
                      </FormErrorMessage>
                    </FormControl>{" "}
                    <FormControl
                      mt={5}
                      isInvalid={!!errors.address?.neighborhood}
                    >
                      <FormLabel display="flex" ms="4px" fontSize="sm">
                        Bairro<Text>*</Text>
                      </FormLabel>
                      <Input
                        size="sm"
                        isRequired={true}
                        fontSize="sm"
                        {...register("address.neighborhood", {
                          required: true,
                          maxLength: {
                            value: 80,
                            message: "Esse campo tem limite de 80 caracteres",
                          },
                        })}
                        ms={{ base: "0px", md: "0px" }}
                        placeholder="Bairro"
                        required
                      />
                      <FormErrorMessage>
                        {errors.address?.neighborhood?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </VStack>
                </SimpleGrid>
                <VStack>
                  <Button
                    mt={5}
                    type="submit"
                    variant={"outline"}
                    w={`100%`}
                    color={"green"}
                  >
                    Salvar
                  </Button>
                </VStack>
              </form>
            </ModalBody>

            <ModalFooter></ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default EditCustomer;
