import {
    HStack,
    IconButton,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    Tooltip,
    useDisclosure,
    useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import CustomButton from "components/CustomButton";
import FormRemoteSelectInput, {
    SelectOptionType,
} from "components/FormRemoteSelectInput";
import { PersonIcon } from "components/icons/Icons";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { } from "react-icons/fa";
import {
    atribuirClientOnWallet,
    findAllClientsRecurrences,
} from "services/api.service";

const AddPromoterToWallet: React.FC<{
  walletId: string;
  oncloseMainModal: () => void;
}> = ({ walletId, oncloseMainModal }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const { mutateAsync: linkWallet, isLoading: isLoadingNewRecurrence } =
    useMutation(atribuirClientOnWallet);

  const { control, handleSubmit, setValue } = useForm<{
    clientId: SelectOptionType;
  }>();

  const handleLinkWallet = async ({
    clientId,
  }: {
    clientId: SelectOptionType;
  }) => {
    try {
      await linkWallet({
        walletId: walletId,
        clientId: clientId.value,
      });
      toast({
        title: "Cliente vinculado com sucesso",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onClose();
      oncloseMainModal();
    } catch (error) {
      toast({
        title: isAxiosError(error)
          ? error?.response?.data?.message
          : "Ocorreu um erro ao realizar o link",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (isOpen) {
      setValue("clientId", null);
    }
  }, [isOpen, setValue]);

  return (
    <>
      <Tooltip label="Informar Promoter">
        <IconButton
          aria-label="Informar Promoter"
          rounded="md"
          size="xs"
          variant="outline"
          onClick={onOpen}
          icon={<PersonIcon />}
        />
      </Tooltip>
      {isOpen && (
        <Modal
          isOpen={isOpen}
          onClose={() => {
            onClose();
            oncloseMainModal();
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Vincular Promoter</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text mb={6}>
                Informe o cliente que deseja vincular a esta carteira
              </Text>
              <FormRemoteSelectInput
                control={control}
                name="clientId"
                label="Promoter"
                loadDataFn={({ value, cb }) =>
                  findAllClientsRecurrences({
                    filter: value,
                    limit: 20,
                    page: 0,
                  }).then((retorno) => {
                    if (retorno?.registers?.length > 0) {
                      cb([
                        { label: "Escolher uma opção...", value: "" },
                        ...retorno.registers?.map((d: any) => ({
                          label: d.name,
                          value: d.id,
                        })),
                      ]);
                    } else {
                      cb([]);
                    }
                  })
                }
              />
            </ModalBody>
            <ModalFooter>
              <HStack spacing={4}>
                <CustomButton
                  colorScheme="red"
                  onClick={() => {
                    onClose();
                    oncloseMainModal();
                  }}
                >
                  Fechar
                </CustomButton>
                <CustomButton
                  onClick={handleSubmit(handleLinkWallet)}
                  colorScheme="green"
                  isLoading={isLoadingNewRecurrence}
                >
                  Vincular
                </CustomButton>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default AddPromoterToWallet;
