import {
    Button,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure,
    useToast,
    VStack,
} from "@chakra-ui/react";
import FormRemoteSelectInput, {
    SelectOptionType,
} from "components/FormRemoteSelectInput";
import InputForm from "components/InputForm";
import InputNumber from "components/InputNumber";
import PercentageNumberInput from "components/PercentageInput";
import { queryClient } from "index";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FaPlus } from "react-icons/fa";
import {
    createInsuranceModel,
    findAllClients,
    findAllCompanys,
} from "services/api.service";

export type CreateModelForm = {
  companyId: SelectOptionType;
  name: string;
  pricePerDay: number;
  companyToken: string;
  price: number;
  token: string;
  customerReceiverId: SelectOptionType;
};

const CreateModel: React.FC<{
  onClose: () => void;
}> = ({ onClose: onCloseMain }) => {
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { handleSubmit, control, reset } = useForm<CreateModelForm>();

  const save = async (dataForm: CreateModelForm) => {
    setLoading(true);
    createInsuranceModel({
      ...dataForm,
      companyId: dataForm.companyId?.value,
      customerReceiverId: dataForm.customerReceiverId?.value,
    })
      .then(() => {
        onClose();
        onCloseMain();
        queryClient.refetchQueries({
          queryKey: ["findAllInsurances"],
          type: "active",
        });
        toast({
          title: "Sucesso",
          description: "Segurado adicionado com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: "Erro",
          description: err?.response?.data?.message || "Erro ao criar Segurado",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    isOpen && reset({});
  }, [isOpen, reset]);

  return (
    <>
      <Button
        onClick={() => {
          onOpen();
          reset();
        }}
        size="sm"
        rounded="sm"
        variant="outline"
        colorScheme="brand"
      >
        <FaPlus />
        <Text ml={2}>Criar Modelo</Text>
      </Button>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Criar Modelo de Seguro</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={3}>
                <FormRemoteSelectInput
                  control={control}
                  label="Recebedor do seguro"
                  required
                  noOptionsMessage={() => {
                    return <>Nenhuma opção encontrada. </>;
                  }}
                  loadDataFn={({ value, cb }) =>
                    findAllClients({ filter: value, limit: 20 }).then(
                      (retorno) => {
                        if (retorno?.registers?.length > 0) {
                          cb(
                            retorno.registers?.map((d: any) => ({
                              label: `${d.name}`,
                              value: d.id,
                            }))
                          );
                        } else {
                          cb([]);
                        }
                      }
                    )
                  }
                  name="customerReceiverId"
                />
                <FormRemoteSelectInput
                  control={control}
                  label="Filtrar para empresa"
                  required
                  noOptionsMessage={() => {
                    return <>Nenhuma opção encontrada. </>;
                  }}
                  loadDataFn={({ value, cb }) =>
                    findAllCompanys({ filter: value, limit: 20, page: 0 }).then(
                      (retorno) => {
                        if (retorno?.registers?.length > 0) {
                          cb(
                            retorno.registers?.map((d: any) => ({
                              label: `${d.name}`,
                              value: d.id,
                            }))
                          );
                        } else {
                          cb([]);
                        }
                      }
                    )
                  }
                  name="companyId"
                />
                <InputForm
                  control={control}
                  name="name"
                  label="Nome *"
                  type="text"
                  rules={{
                    required: "Campo obrigatório",
                  }}
                />
                <InputForm
                  control={control}
                  name="companyToken"
                  label="Token da empresa *"
                  type="text"
                  rules={{
                    required: "Campo obrigatório",
                  }}
                />
                <InputForm
                  control={control}
                  name="token"
                  label="Token do contrato *"
                  type="text"
                  rules={{
                    required: "Campo obrigatório",
                  }}
                />
                <InputNumber
                  control={control}
                  label="Valor do seguro"
                  name="price"
                  rules={{
                    required: "Campo obrigatório",
                  }}
                />
                <InputNumber
                  control={control}
                  label="Valor diário do seguro"
                  name="pricePerDay"
                  rules={{
                    required: "Campo obrigatório",
                  }}
                />
                <PercentageNumberInput
                  control={control}
                  label="Taxa do seguro"
                  name="fee"
                  rules={{
                    required: "Campo obrigatório",
                  }}
                />
              </VStack>
            </ModalBody>
            <ModalFooter>
              <HStack spacing={4}>
                <Button
                  colorScheme="red"
                  isLoading={loading}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  colorScheme="green"
                  isLoading={loading}
                  onClick={handleSubmit(save)}
                >
                  Adicionar
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default CreateModel;
