import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    SimpleGrid,
    Text,
    useToast,
    VStack,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import FormRemoteSelectInput, {
    SelectOptionType,
} from "components/FormRemoteSelectInput";
import InputMaskForm from "components/InputMask";
import SelectForm from "components/SelectForm";
import useAuth from "contexts/useAuth";
import React from "react";
import { useForm } from "react-hook-form";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import {
    createCustomer,
    findAllCompanys,
    findAllPlans,
    findCep,
    findCnpj,
} from "services/api.service";
import { validCnpj } from "services/validDocument";
import { onlyNumbers } from "utils/number";
export interface RegisterCustomerForm {
  name: string;
  nameSecond: string;
  email: string;
  company: SelectOptionType;
  plan: SelectOptionType;
  password: string;
  document: string;
  phone?: string;
  address: {
    cep: string;
    state: string;
    city: string;
    street: string;
    number: string;
    complement: string;
    neighborhood: string;
    cepId: number;
  };
  responsable: {
    name: string;

    document: string;

    phone: string;

    birthDate: string;

    secondDocument: string;

    email: string;

    address: {
      cep: string;
      state: string;
      city: string;
      street: string;
      number: string;
      complement: string;
      neighborhood: string;
      cepId: number;
    };
  };
}

const RegisterCustomer: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose: onCloseSafe }) => {
  const { userData } = useAuth();
  const [companyId, setCompanyId] = React.useState<string | null>(null);
  const [typeUser, setTypeUser] = React.useState<"PF" | "PJ">("PJ");
  const toast = useToast();
  const {
    register,
    setValue,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<RegisterCustomerForm>();

  const [show, setShow] = React.useState(false);

  const handleClick = () => setShow(!show);

  const onClose = () => {
    onCloseSafe();
  };

  const { mutateAsync: createCustomerMutation, isLoading } =
    useMutation(createCustomer);

  const onSubmit = (data: RegisterCustomerForm) => {
    data.responsable.document = onlyNumbers(data.responsable.document);
    data.responsable.phone = onlyNumbers(data.responsable.phone);
    data.document = onlyNumbers(data.document);
    data.phone = onlyNumbers(data.phone);

    createCustomerMutation({
      ...data,
      ...(typeUser === "PF"
        ? {
            name: data.responsable.name,
            document: onlyNumbers(data.responsable.document),
            phone: onlyNumbers(data.responsable.phone),
            birthDate: data.responsable.birthDate,
            email: data.responsable.email,
            address: data.responsable.address,
          }
        : {
            document: onlyNumbers(data.document),
          }),
      companyId: data.company?.value,
      planId: data.plan?.value ? Number(data.plan?.value) : undefined,
    })
      .then(() => {
        onClose();
        toast({
          title: "Sucesso",
          description: "Cliente atualizado com sucesso",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: "Error",
          description: err.response?.data?.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const blurCepResponsable = async (cep: string) => {
    cep = onlyNumbers(cep);

    if (cep?.match(/^\d{8}$/)) {
      findCep(cep)
        .then((response) => {
          setValue("responsable.address.cepId", response.id);
          setValue("responsable.address.cep", response.cep);
          setValue("responsable.address.state", response.state.name);
          setValue("responsable.address.city", response.City.name);
          setValue("responsable.address.street", response.address);
          setValue("responsable.address.neighborhood", response.neigherhood);
        })
        .catch((err) => {
          toast({
            title: "Error",
            description: err?.response?.data?.message,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        });
    }
  };

  const consultCnpj = async (cnpj: string) => {
    cnpj = onlyNumbers(cnpj);

    if (validCnpj(cnpj)) {
      findCnpj(cnpj)
        .then((response) => {
          setValue("nameSecond", response.estabelecimento.nome_fantasia);
          setValue("name", response.razao_social);
          setValue("email", response.estabelecimento.email);
          setValue(
            "phone",
            response.estabelecimento.ddd1 + response.estabelecimento.telefone1
          );
          setValue(
            "address.street",
            response.estabelecimento.tipo_logradouro +
              " " +
              response.estabelecimento.logradouro
          );
          setValue("address.number", response.estabelecimento.numero);
          setValue("address.complement", response.estabelecimento.complemento);
          setValue("address.neighborhood", response.estabelecimento.bairro);
          setValue("address.cep", response.estabelecimento.cep);
          setValue("address.state", response.estabelecimento.estado.nome);
          setValue("address.city", response.estabelecimento.cidade.nome);
          setValue("address.cepId", response.cepId);
        })
        .catch((err) => {
          toast({
            title: "Error",
            description: err?.response?.data?.message,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        });
    } else {
      toast({
        title: "Error",
        description: "CNPJ inválido",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minW={{ lg: "1100px", md: "700px", sm: "400px" }}>
        <ModalHeader>Criar Cliente</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            {!userData.isCustomerAdmin && (
              <FormRemoteSelectInput
                control={control}
                name="company"
                required
                registerOptions={{
                  required: "Campo obrigatório",
                }}
                onChange={(value) => {
                  setCompanyId(value.value);
                  setValue("company", value);
                }}
                label="Empresa"
                loadDataFn={({ value, cb }) =>
                  findAllCompanys({ filter: value, limit: 20, page: 0 }).then(
                    (retorno) => {
                      if (retorno?.registers?.length > 0) {
                        cb(
                          retorno.registers?.map((d: any) => ({
                            label: d.name,
                            value: d.id,
                          }))
                        );
                      } else {
                        cb([]);
                      }
                    }
                  )
                }
              />
            )}
            <HStack mt={2}>
              <FormRemoteSelectInput
                control={control}
                name="plan"
                registerOptions={{
                  required: "Campo obrigatório",
                }}
                required
                label="Plano"
                size="sm"
                loadDataFn={({ value, cb }) =>
                  findAllPlans({
                    filter: value,
                    limit: 20,
                    page: 0,
                    companyId,
                  }).then((retorno) => {
                    if (retorno?.registers?.length > 0) {
                      cb(
                        retorno.registers?.map((d: any) => ({
                          label: d.name,
                          value: d.id,
                        }))
                      );
                    } else {
                      cb([]);
                    }
                  })
                }
              />
              <SelectForm
                control={control}
                name="typeUser"
                label="Tipo de usuário"
                onChange={(value) => {
                  setTypeUser(value as "PJ" | "PF");
                }}
                options={[
                  { label: "Pessoa Jurídica", value: "PJ" },
                  { label: "Pessoa Física", value: "PF" },
                ]}
              />
            </HStack>
            <SimpleGrid columns={{ md: 2, sm: 1 }} spacing={3}>
              {typeUser === "PJ" && (
                <VStack m={0}>
                  <Text fontWeight="bold">Dados da empresa</Text>
                  <FormControl mt={5} isInvalid={!!errors?.document}>
                    <FormLabel display="flex" ms="4px" fontSize="sm">
                      CNPJ<Text>*</Text>
                    </FormLabel>
                    <Input
                      size="sm"
                      isRequired={true}
                      isInvalid={!!errors.document}
                      fontSize="sm"
                      name="document"
                      {...register("document", {
                        required: true,
                        validate: (value) => {
                          value = onlyNumbers(value);
                          if (validCnpj(value)) return true;
                          return "Não é válido";
                        },
                      })}
                      onBlur={(e) => {
                        consultCnpj(e.target.value);
                      }}
                      ms={{ base: "0px", md: "0px" }}
                      type="tel"
                      placeholder="Nome"
                      required
                    />
                    <FormErrorMessage>
                      {errors.document?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl mt={5} isInvalid={!!errors.email}>
                    <FormLabel display="flex" ms="4px" fontSize="sm">
                      E-mail<Text>*</Text>
                    </FormLabel>
                    <Input
                      size="sm"
                      fontSize="sm"
                      name="email"
                      {...register("email", {
                        required: "Esse campo é obrigatório",
                      })}
                      ms={{ base: "0px", md: "0px" }}
                      type="email"
                      autoComplete="email"
                      placeholder="mail@mail.com"
                      required
                    />
                    <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                  </FormControl>
                  <FormControl mt={5} isInvalid={!!errors.name}>
                    <FormLabel display="flex" ms="4px" fontSize="sm">
                      Razão social<Text>*</Text>
                    </FormLabel>
                    <Input
                      size="sm"
                      isRequired={true}
                      isInvalid={!!errors.name}
                      fontSize="sm"
                      {...register("name", { required: true })}
                      ms={{ base: "0px", md: "0px" }}
                      type="text"
                      placeholder="Nome"
                      required
                    />
                    <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
                  </FormControl>
                  <FormControl mt={5} isInvalid={!!errors.nameSecond}>
                    <FormLabel display="flex" ms="4px" fontSize="sm">
                      Nome Fantasia<Text>*</Text>
                    </FormLabel>
                    <Input
                      size="sm"
                      isRequired={true}
                      isInvalid={!!errors.name}
                      fontSize="sm"
                      {...register("nameSecond", { required: true })}
                      ms={{ base: "0px", md: "0px" }}
                      type="text"
                      placeholder="Nome Fantasia"
                      required
                    />
                    <FormErrorMessage>
                      {errors.nameSecond?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <InputMaskForm
                    mask="(99) 99999-9999"
                    size="sm"
                    isRequired={true}
                    control={control}
                    name="phone"
                    label="Telefone"
                    type="tel"
                    placeholder="Telefone"
                    required
                  />
                  <FormControl mt={5} mb={5} isInvalid={!!errors.password}>
                    <FormLabel
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      display="flex"
                    >
                      Senha<Text>*</Text>
                    </FormLabel>
                    <InputGroup size="md">
                      <Input
                        size="sm"
                        {...register("password", {
                          required: true,
                          minLength: {
                            message: "A senha precisa conter 8 caracteres",
                            value: 8,
                          },
                        })}
                        placeholder="Min. 8 characters"
                        required
                        type={show ? "text" : "password"}
                      />
                      <InputRightElement display="flex" alignItems="center">
                        <Icon
                          _hover={{ cursor: "pointer" }}
                          as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                          onClick={handleClick}
                        />
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>
                      {errors.password?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <Text fontWeight="bold">Endereço da Empresa</Text>
                  <InputMaskForm
                    mask="99999-999"
                    size="sm"
                    isRequired={true}
                    control={control}
                    name="address.cep"
                    label="CEP"
                    type="tel"
                    placeholder="CEP"
                  />
                  <FormControl
                    isDisabled={true}
                    mt={5}
                    isInvalid={!!errors.address?.state}
                  >
                    <FormLabel display="flex" ms="4px" fontSize="sm">
                      Estado<Text>*</Text>
                    </FormLabel>
                    <Input
                      size="sm"
                      isRequired={true}
                      fontSize="sm"
                      {...register("address.state", {
                        required: true,
                      })}
                      ms={{ base: "0px", md: "0px" }}
                      placeholder="Estado"
                      required
                    />
                    <FormErrorMessage>
                      {errors.address?.state?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isDisabled={true}
                    mt={5}
                    isInvalid={!!errors.address?.city}
                  >
                    <FormLabel display="flex" ms="4px" fontSize="sm">
                      Cidade<Text>*</Text>
                    </FormLabel>
                    <Input
                      size="sm"
                      isRequired={true}
                      fontSize="sm"
                      {...register("address.city", {
                        required: true,
                      })}
                      ms={{ base: "0px", md: "0px" }}
                      placeholder="Cidade"
                      required
                    />
                    <FormErrorMessage>
                      {errors.address?.city?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl mt={5} isInvalid={!!errors.address?.street}>
                    <FormLabel display="flex" ms="4px" fontSize="sm">
                      Rua<Text>*</Text>
                    </FormLabel>
                    <Input
                      size="sm"
                      isRequired={true}
                      fontSize="sm"
                      {...register("address.street", {
                        required: true,
                        maxLength: {
                          value: 80,
                          message: "Esse campo tem limite de 80 caracteres",
                        },
                      })}
                      ms={{ base: "0px", md: "0px" }}
                      placeholder="Rua"
                      required
                    />
                    <FormErrorMessage>
                      {errors.address?.street?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl mt={5} isInvalid={!!errors.address?.number}>
                    <FormLabel display="flex" ms="4px" fontSize="sm">
                      Número<Text>*</Text>
                    </FormLabel>
                    <Input
                      size="sm"
                      isRequired={true}
                      fontSize="sm"
                      {...register("address.number", {
                        required: true,
                        maxLength: {
                          value: 10,
                          message: "Esse campo tem limite de 10 caracteres",
                        },
                      })}
                      ms={{ base: "0px", md: "0px" }}
                      placeholder="Número"
                      required
                    />
                    <FormErrorMessage>
                      {errors.address?.number?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl mt={5} isInvalid={!!errors.address?.complement}>
                    <FormLabel display="flex" ms="4px" fontSize="sm">
                      Complemento<Text>*</Text>
                    </FormLabel>
                    <Input
                      size="sm"
                      isRequired={true}
                      fontSize="sm"
                      {...register("address.complement", {
                        required: true,
                        maxLength: {
                          value: 80,
                          message: "Esse campo tem limite de 80 caracteres",
                        },
                      })}
                      ms={{ base: "0px", md: "0px" }}
                      placeholder="Complemento"
                      required
                    />
                    <FormErrorMessage>
                      {errors.address?.complement?.message}
                    </FormErrorMessage>
                  </FormControl>{" "}
                  <FormControl
                    mt={5}
                    isInvalid={!!errors.address?.neighborhood}
                  >
                    <FormLabel display="flex" ms="4px" fontSize="sm">
                      Bairro<Text>*</Text>
                    </FormLabel>
                    <Input
                      size="sm"
                      isRequired={true}
                      fontSize="sm"
                      {...register("address.neighborhood", {
                        required: true,
                        maxLength: {
                          value: 80,
                          message: "Esse campo tem limite de 80 caracteres",
                        },
                      })}
                      ms={{ base: "0px", md: "0px" }}
                      placeholder="Bairro"
                      required
                    />
                    <FormErrorMessage>
                      {errors.address?.neighborhood?.message}
                    </FormErrorMessage>
                  </FormControl>
                </VStack>
              )}

              <VStack>
                <Text fontWeight="bold">Dados do Responsável</Text>
                <FormControl mt={5} isInvalid={!!errors.responsable?.name}>
                  <FormLabel display="flex" ms="4px" fontSize="sm">
                    Nome<Text>*</Text>
                  </FormLabel>
                  <Input
                    size="sm"
                    isRequired={true}
                    fontSize="sm"
                    {...register("responsable.name", { required: true })}
                    ms={{ base: "0px", md: "0px" }}
                    type="text"
                    placeholder="Nome"
                    required
                  />
                  <FormErrorMessage>
                    {errors.responsable?.name?.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl mt={5} isInvalid={!!errors.responsable?.email}>
                  <FormLabel display="flex" ms="4px" fontSize="sm">
                    E-mail<Text>*</Text>
                  </FormLabel>
                  <Input
                    size="sm"
                    fontSize="sm"
                    {...register("responsable.email", {
                      required: "Esse campo é obrigatório",
                    })}
                    ms={{ base: "0px", md: "0px" }}
                    type="email"
                    autoComplete="email"
                    placeholder="mail@mail.com"
                    required
                  />
                  <FormErrorMessage>
                    {errors.responsable?.email?.message}
                  </FormErrorMessage>
                </FormControl>

                {typeUser === `PF` && (
                  <FormControl mt={5} mb={5} isInvalid={!!errors.password}>
                    <FormLabel
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      display="flex"
                    >
                      Senha<Text>*</Text>
                    </FormLabel>
                    <InputGroup size="md">
                      <Input
                        size="sm"
                        {...register("password", {
                          required: true,
                          minLength: {
                            message: "A senha precisa conter 8 caracteres",
                            value: 8,
                          },
                        })}
                        placeholder="Min. 8 characters"
                        required
                        type={show ? "text" : "password"}
                      />
                      <InputRightElement display="flex" alignItems="center">
                        <Icon
                          _hover={{ cursor: "pointer" }}
                          as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                          onClick={handleClick}
                        />
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>
                      {errors.password?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}

                <InputMaskForm
                  mask="999.999.999-99"
                  size="sm"
                  isRequired={true}
                  control={control}
                  name="responsable.document"
                  label="CPF"
                  type="tel"
                  placeholder="CPF"
                  required
                />
                <InputMaskForm
                  mask="(99) 99999-9999"
                  size="sm"
                  isRequired={true}
                  control={control}
                  name="responsable.phone"
                  label="Telefone"
                  type="tel"
                  placeholder="Telefone"
                  required
                />

                <FormControl mt={5} isInvalid={!!errors.responsable?.birthDate}>
                  <FormLabel display="flex" ms="4px" fontSize="sm">
                    Data de Nascimento<Text>*</Text>
                  </FormLabel>
                  <Input
                    size="sm"
                    isRequired={true}
                    fontSize="sm"
                    {...register("responsable.birthDate", {
                      required: true,
                    })}
                    ms={{ base: "0px", md: "0px" }}
                    type="date"
                    placeholder="Data de nascimento"
                    required
                  />
                  <FormErrorMessage>
                    {errors.responsable?.phone?.message}
                  </FormErrorMessage>
                </FormControl>

                <Text fontWeight="bold">Endereço do Responsável</Text>

                <InputMaskForm
                  mask="99999-999"
                  size="sm"
                  isRequired={true}
                  control={control}
                  name="responsable.address.cep"
                  label="CEP"
                  type="tel"
                  placeholder="CEP"
                  onBlur={(e) => {
                    blurCepResponsable(e.target.value);
                  }}
                />
                <FormControl
                  mt={5}
                  isDisabled={true}
                  isInvalid={!!errors.responsable?.address?.state}
                >
                  <FormLabel display="flex" ms="4px" fontSize="sm">
                    Estado<Text>*</Text>
                  </FormLabel>
                  <Input
                    size="sm"
                    isRequired={true}
                    fontSize="sm"
                    {...register("responsable.address.state", {
                      required: true,
                    })}
                    ms={{ base: "0px", md: "0px" }}
                    placeholder="Estado"
                    required
                  />
                  <FormErrorMessage>
                    {errors.responsable?.address?.state?.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  mt={5}
                  isDisabled={true}
                  isInvalid={!!errors.responsable?.address?.city}
                >
                  <FormLabel display="flex" ms="4px" fontSize="sm">
                    Cidade<Text>*</Text>
                  </FormLabel>
                  <Input
                    size="sm"
                    isRequired={true}
                    fontSize="sm"
                    {...register("responsable.address.city", {
                      required: true,
                    })}
                    ms={{ base: "0px", md: "0px" }}
                    placeholder="Cidade"
                    required
                  />
                  <FormErrorMessage>
                    {errors.responsable?.address?.city?.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  mt={5}
                  isInvalid={!!errors.responsable?.address?.street}
                >
                  <FormLabel display="flex" ms="4px" fontSize="sm">
                    Rua<Text>*</Text>
                  </FormLabel>
                  <Input
                    size="sm"
                    isRequired={true}
                    fontSize="sm"
                    {...register("responsable.address.street", {
                      required: true,
                      maxLength: {
                        value: 80,
                        message: "Esse campo tem limite de 80 caracteres",
                      },
                    })}
                    ms={{ base: "0px", md: "0px" }}
                    placeholder="Rua"
                    required
                  />
                  <FormErrorMessage>
                    {errors.responsable?.address?.street?.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  mt={5}
                  isInvalid={!!errors.responsable?.address?.number}
                >
                  <FormLabel display="flex" ms="4px" fontSize="sm">
                    Número<Text>*</Text>
                  </FormLabel>
                  <Input
                    size="sm"
                    isRequired={true}
                    fontSize="sm"
                    {...register("responsable.address.number", {
                      required: true,
                      maxLength: {
                        value: 80,
                        message: "Esse campo tem limite de 10 caracteres",
                      },
                    })}
                    ms={{ base: "0px", md: "0px" }}
                    placeholder="Número"
                    required
                  />
                  <FormErrorMessage>
                    {errors.responsable?.address?.number?.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  mt={5}
                  isInvalid={!!errors.responsable?.address?.complement}
                >
                  <FormLabel display="flex" ms="4px" fontSize="sm">
                    Complemento<Text>*</Text>
                  </FormLabel>
                  <Input
                    size="sm"
                    isRequired={true}
                    fontSize="sm"
                    {...register("responsable.address.complement", {
                      required: true,
                      maxLength: {
                        value: 80,
                        message: "Esse campo tem limite de 80 caracteres",
                      },
                    })}
                    ms={{ base: "0px", md: "0px" }}
                    placeholder="Complemento"
                    required
                  />
                  <FormErrorMessage>
                    {errors.responsable?.address?.complement?.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  mt={5}
                  isInvalid={!!errors.responsable?.address?.neighborhood}
                >
                  <FormLabel display="flex" ms="4px" fontSize="sm">
                    Bairro<Text>*</Text>
                  </FormLabel>
                  <Input
                    size="sm"
                    isRequired={true}
                    fontSize="sm"
                    {...register("responsable.address.neighborhood", {
                      required: true,
                      maxLength: {
                        value: 80,
                        message: "Esse campo tem limite de 80 caracteres",
                      },
                    })}
                    ms={{ base: "0px", md: "0px" }}
                    placeholder="Bairro"
                    required
                  />
                  <FormErrorMessage>
                    {errors.responsable?.address?.neighborhood?.message}
                  </FormErrorMessage>
                </FormControl>
              </VStack>
            </SimpleGrid>

            <VStack>
              <Button
                mt={5}
                isLoading={isLoading}
                type="submit"
                variant={"outline"}
                w={`100%`}
                color={"green"}
              >
                Salvar
              </Button>
            </VStack>
          </form>
        </ModalBody>

        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RegisterCustomer;
