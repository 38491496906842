// Chakra imports
import { Box, Flex, HStack, SimpleGrid, useToast } from '@chakra-ui/react';

import CustomButton from 'components/CustomButton';
import TableComponent from 'components/TableComponent';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Column } from 'react-table';
import { findAllRecurrencyLinks } from 'services/api.service';
import CreateLinkRecurrence from './links/CreateRecurrence';
import ViewLinkRecurrence from './links/ViewLinkRecurrence';

export default function LinksRecurrency() {
  const history = useHistory();
  const toast = useToast();
  const columns = useMemo(
    () =>
      [
        {
          Header: 'Título',
          accessor: 'title',
        },

        {
          accessor: 'createdAt',
          Header: 'Data',
          Cell: ({ value }) => {
            return DateTime.fromISO(value).toFormat('dd/MM/yyyy HH:mm:ss');
          },
        },
        {
          Header: 'Ações',
          accessor: 'actions',
        },
      ] as Column[],
    [],
  );

  const [selectedLinkId, setSelectedLinkId] = React.useState<string | null>(null);

  const actions = (row: any) => (
    <Flex
      align="center"
      justify="flex-start"
      w="100%"
      gap={2}
    >
      <CustomButton
        onClick={() => setSelectedLinkId(row.id)}
        colorScheme="blue"
      >
        Visualizar
      </CustomButton>

      <CustomButton
        onClick={() => {
          navigator.clipboard.writeText(row.link);
          toast({
            title: 'Link copiado',
            description: 'Link copiado com sucesso',
            status: 'success',
          });
        }}
        colorScheme="brand"
      >
        Copiar link
      </CustomButton>
    </Flex>
  );

  return (
    <Box pt={{ base: "80px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1 }}
        spacing={{ base: '20px', xl: '20px' }}
      >
        <TableComponent
          label="Recorrências"
          columnsData={columns}
          right={
            <HStack>
              <CreateLinkRecurrence />
              <CustomButton
                onClick={() => history.push('/access/recurrence-plans')}
                colorScheme="brand"
              >
                Planos
              </CustomButton>
            </HStack>
          }
          queryFn={findAllRecurrencyLinks}
          queryKey="findAllRecurrencyLinks"
          actions={actions}
        />
      </SimpleGrid>

      {selectedLinkId && (
        <ViewLinkRecurrence
          isOpen={!!selectedLinkId}
          onClose={() => setSelectedLinkId(null)}
          linkId={selectedLinkId}
        />
      )}
    </Box>
  );
}
