import { ExternalLinkIcon } from "@chakra-ui/icons";
import { HStack, Link } from "@chakra-ui/react";
import React from "react";

const LinkToPage: React.FC<{
  href: string;
  name: string;
  isExternal?: boolean;
}> = ({ href, name, isExternal = false }) => {
  return (
    <HStack alignItems="center" spacing={1}>
      <Link
        textDecorationLine={`underline`}
        _hover={{ cursor: `pointer` }}
        textDecorationStyle={`dashed`}
        textDecorationThickness={`1px`}
        fontSize={16}
        noOfLines={1}
        href={href}
        isExternal={isExternal}
      >
        {name} {isExternal && <ExternalLinkIcon fontSize={12} />}
      </Link>
    </HStack>
  );
};

export default LinkToPage;
