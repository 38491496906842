import {
    Button,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    useToast,
    VStack,
} from "@chakra-ui/react";
import CustomButton from "components/CustomButton";
import FormRemoteSelectInput, {
    SelectOptionType,
} from "components/FormRemoteSelectInput";
import InputForm from "components/InputForm";
import { queryClient } from "index";
import React from "react";
import { useForm } from "react-hook-form";
import { FaPlus } from "react-icons/fa";
import { createCompany, findAllClients } from "services/api.service";
import { validCnpj, validCpf } from "services/validDocument";
import { onlyNumbers } from "utils/number";

const CreateCompany = () => {
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { handleSubmit, control, reset } = useForm<{
    name: string;
    document: string;
    mainCustomerId: SelectOptionType;
  }>();

  const save = async (dataForm: {
    name: string;
    document: string;
    mainCustomerId: SelectOptionType;
  }) => {
    setLoading(true);
    createCompany({
      name: dataForm.name,
      document: onlyNumbers(dataForm.document),
      mainCustomerId: dataForm.mainCustomerId?.value,
    })
      .then(() => {
        queryClient.refetchQueries({
          queryKey: ["findAllCompanys"],
          type: "active",
        });
        onClose();
        toast({
          title: "Sucesso",
          description: "Empresa adicionada com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: "Erro",
          description: err?.response?.data?.message || "Erro ao criar empresa",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <CustomButton
        isDisabled={false}
        colorScheme="brand"
        variant="solid"
        leftIcon={<FaPlus />}
        onClick={() => {
          onOpen();
          reset();
        }}
      >
        Criar Empresa
      </CustomButton>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Adicionar Empresa</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack>
                <InputForm
                  control={control}
                  name="name"
                  label="Nome"
                  placeholder="Nome"
                  required={true}
                  type="text"
                />
                <InputForm
                  control={control}
                  name="document"
                  label="Documento"
                  placeholder="Documento"
                  required={true}
                  type="text"
                  rules={{
                    required: true,
                    minLength: {
                      value: 11,
                      message: "CPF/CNPJ inválido, mínimo 14 caracteres",
                    },
                    maxLength: {
                      value: 18,
                      message: "CPF/CNPJ inválido, máximo 18 caracteres",
                    },
                    validate: (value) => {
                      value = onlyNumbers(value);
                      if (validCnpj(value)) return true;
                      if (validCpf(value)) return true;
                      return "Não é válido";
                    },
                  }}
                />
                <FormRemoteSelectInput
                  control={control}
                  name="mainCustomerId"
                  label="Cliente principal"
                  size="sm"
                  loadDataFn={({ value, cb }) =>
                    findAllClients({
                      filter: value,
                      limit: 20,
                      page: 0,
                    }).then((retorno) => {
                      if (retorno?.registers?.length > 0) {
                        cb([
                          { label: "Escolher uma opção...", value: "" },
                          ...retorno.registers?.map((d: any) => ({
                            label: d.name,
                            value: d.id,
                          })),
                        ]);
                      } else {
                        cb([]);
                      }
                    })
                  }
                />
              </VStack>
            </ModalBody>
            <ModalFooter>
              <HStack spacing={4}>
                <Button
                  colorScheme="red"
                  isLoading={loading}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  colorScheme="green"
                  isLoading={loading}
                  onClick={handleSubmit(save)}
                >
                  Adicionar
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default CreateCompany;
